import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import Artworks from '../components/Artworks'

import { truncate, useGlobalState } from '../store'
import { getEthereumContract } from '../services/blockchain';
import gif from '../assets/images/loading.gif';

const MyNFTs = () => {
  const [nfts, setNfts] = useState([]);
  const [loadingState, setLoadingState] = useState('not-loaded');
  const [connectedAccount] = useGlobalState('connectedAccount')

  const getWalletNFTs = async () => {
    const contract = await getEthereumContract();
    const totalSupply = await contract.totalSupply();

    const promises = Array.from({ length: totalSupply }, async (_, i) => {
      const tokenId = i + 1;

      try {
        const owner = await contract.ownerOf(tokenId);

        if (owner.toLowerCase() === connectedAccount.toLowerCase()) {
          const rawUri = await contract.tokenURI(tokenId);
          const cleanUri = rawUri.replace('ipfs://', 'https://ipfs.io/ipfs/');
          const metadata = await axios.get(cleanUri);
          const { name, description, image: rawImage } = metadata.data;
          const image = rawImage.replace('ipfs://', 'https://ipfs.io/ipfs/');

          return {
            name,
            description,
            image,
            tokenId,
            owner
          };
        }
      } catch (error) {
        console.error(`Error fetching data for token ID ${tokenId}: `, error);
      }

      return null;
    });

    const allNfts = await Promise.all(promises);
    const ownedNfts = allNfts.filter(Boolean);
    setNfts(ownedNfts);
    setLoadingState('loaded');
  }

  useEffect(() => {
    if (connectedAccount) {
      // console.log(`Connected account is now: ${connectedAccount}`);
      getWalletNFTs();
    } else {
      // console.log('No account is connected');
    }
  }, [connectedAccount]);

  if (loadingState === 'loaded' && !nfts.length) {
    return <div><p>No NFTs found.</p></div>;
  }

  return (
    <div>
      {loadingState === 'loaded' ? (
        <Artworks title="Your Collections" auctions={nfts} showOffer />
      ) : (
        <div className="flex justify-center items-center h-screen">
    <img src={gif} alt="Icon" />
</div>

      )}
    </div>
  );
}

export default MyNFTs;
