

import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react';
import { purchaseNFT } from '../services/blockchain'
import { setGlobalState, useGlobalState } from '../store'
import Countdown from './Countdown'
import icon from '../assets/images/icon.png';
import { Scrollbar } from 'smooth-scrollbar-react';
import { ethers } from 'ethers';

import { getEthereumContract } from '../services/blockchain';



const getRank = (auctionName, jsonData) => {
  if (jsonData) {
    const filteredData = jsonData.filter((item) => item.Name === auctionName);
    if (filteredData.length > 0) {
      return filteredData[0].Rank;
    } else {
      return 0; // Default rank if not found
    }
  }
  return 0; // Default rank if jsonData is not available yet
};

const Artworks = ({ auctions, title, showOffer }) => {
  const [jsonData, setJsonData] = useState(null);
  const [sortValue, setSortValue] = useState('asc');
  const [floorprice, setFloorprice] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/rarity.json');
        if (!response.ok) {
          throw new Error('Error fetching JSON data: ' + response.status);
        }
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };

    fetchData();
  }, []);

  const sortedAuctions = auctions.sort((a, b) => {
    // Get the ranks of auction a and b from the jsonData
    const rankA = getRank(a.name, jsonData);
    const rankB = getRank(b.name, jsonData);

    // Sort based on the sortValue ('asc' or 'desc')
    if (sortValue === 'asc') {
      return rankA - rankB;
    } else {
      return rankB - rankA;
    }
  });

  const handleSortChange = (event) => {
    const selectedValue = event.target.value;
    setSortValue(selectedValue);
  };
  const loadLiveAuctions = async () => {
    try {
      const contract = await getEthereumContract()
      let auctions = await contract.getLiveAuctions()  
      // Calculate floor price from live auctions
      if (auctions.length > 0) {
        let floorPrice = ethers.BigNumber.from(auctions[0].price);
        for (const auction of auctions) {
          if (ethers.BigNumber.from(auction.price).lt(floorPrice)) {
            floorPrice = ethers.BigNumber.from(auction.price);
          }
        }
        // Convert floor price from Wei to Ether
        floorPrice = ethers.utils.formatEther(floorPrice);
        // console.log(`Floor price: ${floorPrice}`);
        setFloorprice(floorPrice);
      }
    } catch (error) {
      reportError(error)
    }
  }

  useEffect(() => {
    loadLiveAuctions();
 }, []);

  return (
    <div className="bg-[#B549F5]  p-4 rounded-[11px] overflow-hidden relative">
      {floorprice &&

      <span className="absolute top-10 left-10 text-white text-s font-medium font-Recoleta mr-2">
  Floor Price : {floorprice} 
  <img src={icon} alt="Icon" className="inline h-4 w-4 ml-1" />
</span> }




  <Scrollbar
    damping={0.1}
    thumbMinSize={20}
    renderByPixels={true}
    alwaysShowTracks={false}
    continuousScrolling={true}
    wheelEventTarget={null}
    plugins={{}}
    onScroll={() => {}}
  >
    <div className="absolute top-4 right-5 flex justify-between items-center">

      <span className="text-white text-xs font-medium font-Recoleta mr-2">Sort By:</span>
      <div>
        <select
          value={sortValue}
          onChange={handleSortChange}
          className="text-white text-xs pr-9 py-2 rounded-md text-base font-medium font-Recoleta appearance-none opacity-90 border-white"
          style={{ backgroundColor: '#9E3ED7' }}
        >
          <option value="asc">Rank: Low to High</option>
          <option value="desc">Rank: High to Low</option>
        </select>
      </div>
    </div>

    <div className="max-h-[calc(100vh-224px)]">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6 py-2.5 text-white  mt-[60px] mr-5 ml-5">
        {sortedAuctions.map((auction, i) => {
          const rank = getRank(auction.name, jsonData); // Get the rank for each auction
          return (
            <Auction
              key={i}
              auction={auction}
              showOffer={showOffer}
              jsonData={jsonData}
              rank={rank} // Pass rank as a prop
            />
          );
        })}
      </div>
    </div>
  </Scrollbar>
</div>

  )
}

const Auction = ({ auction, showOffer, jsonData }) => {
  const [rank, setRank] = useState('');
  const [counterVisible, setCounterVisible] = useState(false);

  useEffect(() => {
    if (jsonData) {
      const filteredData = jsonData.filter((item) => item.Name === auction.name);
      if (filteredData.length > 0) {
        setRank(filteredData[0].Rank);
      } else {
        setRank('Rank not found');
      }
    }
  }, [auction.name, jsonData]);

  useEffect(() => {
    if (auction.live && auction.duration > Date.now()) {
      setCounterVisible(true);
    } else {
      setCounterVisible(false);
    }
  }, [auction.live, auction.duration]);


  const [connectedAccount] = useGlobalState('connectedAccount')

  const onChangePrice = () => {
    setGlobalState('auction', auction)
    setGlobalState('priceModal', 'scale-100')
  }

  const onOfferItem = () => {
    setGlobalState('auction', auction)
    setGlobalState('offerModal', 'scale-100')
  }

  const onPlaceBid = () => {
    setGlobalState('auction', auction)
    setGlobalState('bidModal', 'scale-100')
  }

  const handleNftPurchase = async () => {
    await toast.promise(
      new Promise(async (resolve, reject) => {
        await purchaseNFT(auction)
          .then(() => resolve())
          .catch((error) => reject(error))
      }),
      {
        pending: 'Transfering NFT...',
        success: 'Transfer completed...',
        error: 'Encoutered an error',
      }
    )
  }
  
  // console.log('connectedAccount:', connectedAccount);
  //     console.log('auction.sellerAddress:', auction);
  return (
    <div className="border border-white rounded-md overflow-hidden px-3">
      <div>
        <h3 className="text-white mb-2 font-Recoleta text-[14px] mt-5">Rank: {rank}</h3>
        <div className="relative w-full h-0 pb-[100%]">
          <Link to={'/nft/' + auction.tokenId}>
            <img
              src={auction.image}
              alt={auction.name}
              className="absolute object-cover w-full h-full rounded-[10px]"
            />
          </Link>

          <div className="flex justify-between items-center text-gray-300 px-2">
            <div className="text-white text-[16px] font-Recoleta font-bold mt-2 mb-2">
              <span>{auction.name} </span>
            </div>

            <div className="flex flex-col items-start py-2 px-1">
              <div className="font-bold text-center">
                <div className="flex flex-col items-start py-2 px-1">
                  <div className="font-bold text-center">
                    {auction.live && auction.duration > Date.now() ? (
                      <div>
                        <span className='font-Recoleta font-semibold'>Auction End</span>
                        <div className='font-Recoleta font-semibold'><Countdown timestamp={auction.duration} /></div>
                      </div>
                    ) : (
                      <button>Auction Ended</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h3 className="text-white mb-2 font-Recoleta font-bold text-[17px] mt-5">{auction.name}</h3>

        {showOffer ? (

auction.live && Date.now() < auction.duration ? (
  <button className="bg-transparent w-full h-[40px] p-2 text-center font-bold font-Recoleta border-white border-2 mb-4">
    Auction Live
  </button>
) : (
  <div className="flex justify-start mt-4 mb-4 mx-4">
    <button
      className="bg-transparent outline outline-white outline-2 rounded-[7px] w-full h-[35px] p-2 text-center font-bold font-Recoleta mr-3"
      onClick={onOfferItem}
    >
      List NFT
    </button>
    {/* <button
      className="bg-transparent outline outline-white outline-2 rounded-[7px] w-full h-[35px] p-1 text-center font-bold font-Recoleta"
      onClick={onChangePrice}
    >
      Change
    </button> */}
  </div>
)
) : auction.biddable && auction.live && auction.duration > Date.now() ? (
  <div>
    <div>
      <p className="text-[#D2D2D2] font-Recoleta font-semibold text-[16px] mt-4 mb-2">
        Current Bid
      </p>
      <div className='flex justify-between'>
        <div className="flex items-center mb-2">
          <div className="text-white mr-2 text-[22px] font-Recoleta font-semibold">
            {auction.price}
          </div>
          <img src={icon} alt="Icon" className="w-6 h-6" />
        </div>
        <div className='font-light font-Recoleta text-[16px]'>
        <div className='font-Recoleta font-semibold'><Countdown timestamp={auction.duration} /></div>
        </div>
      </div>
    </div>
    
    {connectedAccount === auction.seller ? (
      
      <button className="bg-transparent w-full h-[40px] p-2 text-center font-bold rounded-[8px] font-Recoleta border-white border-2 mb-2">
        Auction Live
      </button>
    ) : (
      <button
        className="bg-transparent border-2 border-white rounded-[7px] font-Recoleta w-full h-[40px] p-2 text-center font-bold mb-5"
        onClick={onPlaceBid}
      >
        Place a Bid
      </button>
    )}
  </div>
)  : auction?.price <= 0 ? (
  <div>
    <p className="text-[#D2D2D2] font-Recoleta font-semibold text-[16px] mt-4 mb-2">
      Status
    </p>
    <div className="flex items-center mb-2">
      <div className="text-white mr-2 text-[22px] font-Recoleta font-semibold">
        Not Listed
      </div>
    </div>
  </div>
) : auction.live && auction.duration > Date.now() && connectedAccount != auction.seller ? (
  <div>
    <div>
      <p className="text-[#D2D2D2] font-Recoleta font-semibold text-[16px] mt-4 mb-2">
        Price
      </p>
      <div className="flex items-center mb-2">
        <div className="text-white mr-2 text-[22px] font-Recoleta font-semibold">
          {auction.price}
        </div>
        <img src={icon} alt="Icon" className="w-6 h-6" />
      </div>
    </div>
    <div>
      <span className='font-Recoleta font-semibold'>Auction End</span>
      <div className='font-Recoleta font-semibold'><Countdown timestamp={auction.duration} /></div>
    </div>
    <button
      className="bg-transparent border-2 border-white rounded-[7px] font-Recoleta w-full h-[40px] p-2 text-center font-bold mb-5"
      onClick={handleNftPurchase}
    >
      Purchase now
    </button>
  </div>
  // New condition integrated here
) : auction.live && auction.duration > Date.now() && connectedAccount === auction.seller ? (
  <div>
    <div>
      <p className="text-[#D2D2D2] font-Recoleta font-semibold text-[16px] mt-4 mb-2">
        Price
      </p>
      <div className="flex items-center mb-2">
        <div className="text-white mr-2 text-[22px] font-Recoleta font-semibold">
          {auction.price}
        </div>
        <img src={icon} alt="Icon" className="w-6 h-6" />
      </div>
    </div>
    <div>
      <span className='font-Recoleta font-semibold'>Auction End</span>
      <div className='font-Recoleta font-semibold'><Countdown timestamp={auction.duration} /></div>
    </div>
    <button
      className="bg-transparent border-2 border-white rounded-[7px] font-Recoleta w-full h-[40px] p-2 text-center font-bold mb-5"
    >
      Auction is Live
    </button>
  </div>
) : null
}
      </div>
    </div>
  )
}

export default Artworks