import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Scrollbar } from 'smooth-scrollbar-react';


const formatOwner = (address) => {
  const prefix = address.slice(0, 4);
  const suffix = address.slice(-4);
  return `${prefix}....${suffix}`;
};

const Artworks2 = ({ auctions }) => {
  return (
    <div className="max-h-[calc(100vh-10px)] bg-[#B549F5] p-4  py-4 rounded-[11px] overflow-hidden relative">
      <Scrollbar
    damping={0.1}
    thumbMinSize={20}
    renderByPixels={true}
    alwaysShowTracks={false}
    continuousScrolling={true}
    wheelEventTarget={null}
    plugins={{}}
    onScroll={() => {}}
  >
      <div className="grid grid-cols-1 gap-8 py-4 text-white mr-8 ml-8 ">
        <div className="space-y-6 ">
          

          <div className="flex  px-6 p-4 items-center font-Recoleta text-[20px]">
            <p className="w-32 ml-10 mr-16">Image</p>
            <p className="w-[240px]">Name</p>
            <p className="w-[200px]">Price</p>
            <p className="w-[200px]">to</p>
            <p className="w-[200px]">From</p>
            <p className="w-32">Time</p>
          </div>

          <hr className="border-gray-300 pb-4   " />

          {auctions.map((auction, index) => {
            const durationDate = new Date(auction.duration);
            const relativeTime = formatDistanceToNow(durationDate, { addSuffix: true });
            const formattedOwner = formatOwner(auction.owner);
            const formattedSeller = formatOwner(auction.seller);

            return (
              <div key={index} className="flex bg-transparent border-white border rounded-[10px] px-2 p-4 items-center font-Recoleta text-[18px] ">
                {/* Display the image */}
                <img src={auction.image} alt={auction.name} className="w-24 h-24 object-cover mr-20 ml-10 rounded-[10px] " />

                <p className="w-[280px] text-[20px]">{auction.name}</p>
                <p className="w-[160px] ">{auction.price}</p>
                <p className="w-[200px]">{formattedOwner}</p>
                <p className="w-[200px]">{formattedSeller}</p>
                <p className="w-62">{relativeTime}</p>
              </div>
            );
          })}
        </div>
      </div>
      </Scrollbar>
    </div>
  );
};

export default Artworks2;
