import { useEffect } from 'react'
import Artworks2 from '../components/Artworks2'
import CreateNFT from '../components/CreateNFT'
import Empty from '../components/Empty'
import Hero from '../components/Hero'
import { loadSoldAuctions } from '../services/blockchain'
import { useGlobalState } from '../store'

const Home = () => {
  const [auctions] = useGlobalState('auctions')
  
  useEffect(async () => {
    await loadSoldAuctions()
  }, [])

  return (
    <div className="w-4/5 mx-auto mt-11">
      {auctions.length > 0 ? <Artworks2 auctions={auctions} /> : <Empty />}
    </div>
  )
}

export default Home