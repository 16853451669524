import { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { toast } from 'react-toastify'
import picture6 from '../assets/images/picture6.png'
import { createNFTItem } from '../services/blockchain'
import { setGlobalState, useGlobalState } from '../store'

const CreateNFT = () => {
  const [boxModal] = useGlobalState('boxModal')
  const [imgBase64] = useState(picture6) // Default image URL
  const [count, setCount] = useState(1) // Counter state

  const handleIncrement = () => {
    setCount(prevCount => prevCount + 1);
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(prevCount => prevCount - 1);
    }
  };

  const handleMint = async () => {
    await toast.promise(
      createNFTItem(count),
      {
        pending: 'Minting in progress...',
        success: 'Minting completed!',
        error: 'An error occurred',
      }
    );
    onClose();
  };

  const onClose = () => {
    setGlobalState('boxModal', 'scale-0')
  }

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center
      justify-center bg-black bg-opacity-50 transform
      transition-transform duration-300 ${boxModal}`}
    >
      <div
        className="bg-[#151c25] shadow-xl shadow-[#25bd9c] rounded-xl
        w-11/12 sm:w-2/5 h-7/12 p-6"
      >
        <div className="flex justify-between items-center text-gray-400">
          <p className="font-semibold italic">Mint NFT</p>
          
          <button
            type="button"
            onClick={onClose}
            className="border-0 bg-transparent focus:outline-none"
          >
            <FaTimes />
          </button>
        </div>

        <div className="flex justify-center items-center mt-5">
          <div className="shrink-0 rounded-xl overflow-hidden h-20 w-20">
            <img
              src={imgBase64}
              alt="Artwork"
              className="h-full w-full object-cover cursor-pointer"
            />
          </div>
        </div>

        <div className="flex items-center justify-center mt-5">
          <button
            type="button"
            onClick={handleDecrement}
            className="text-2xl text-[#25bd9c] focus:outline-none"
          >
            -
          </button>
          <span className="text-xl mx-3">{count}</span>
          <button
            type="button"
            onClick={handleIncrement}
            className="text-2xl text-[#25bd9c] focus:outline-none"
          >
            +
          </button>
        </div>

        <button
          type="button"
          onClick={handleMint}
          className="flex justify-center items-center
          w-full text-white text-md bg-[#25bd9c] mt-5
          py-2 px-5 rounded-full drop-shadow-xl border border-transparent
          hover:bg-transparent hover:text-white focus:ring-0
          hover:border hover:border-[#25bd9c] focus:outline-none"
        >
          Mint Now
        </button>
      </div>
    </div>
  )
}

export default CreateNFT
