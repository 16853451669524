import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ChangePrice from './components/ChangePrice'
import Footer from './components/Footer'
import Header from './components/Header'
import OfferItem from './components/OfferItem'
import PlaceBid from './components/PlaceBid'
import { isWalletConnected } from './services/blockchain'
import { useGlobalState } from './store'
import Collections from './views/Collections'
import LandingPage from './views/LandingPage'
import Home from './views/Home'
import Events from './views/Events'
import NFT from './views/NFT'
import MyNFTs from './views/MyNFTs'
import Royalties from './views/Royalties'

const App = () => {
  const [auction] = useGlobalState('auction')
  useEffect(async () => {
    await isWalletConnected()
  }, [])

  return (
    <div
      className="min-h-screen bg-[#9E3ED7] "
    >
      <Header />
      <Routes>
        <Route path="/Market" element={<Home />} />
        <Route path="/activity" element={<Events />} />
        {/* <Route path="/profile" element={<Collections />} /> */}
        <Route path="/Royalties" element={<Royalties />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/profile" element={<MyNFTs />} />
        <Route path="/nft/:id" element={<NFT />} />
      </Routes>
      <Footer />

      {auction ? (
        <>
          {/* <ChangePrice /> */}
          <PlaceBid />
          <OfferItem />
        </>
      ) : null}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        draggable
        theme="dark"
      />
    </div>
  )
}

export default App
