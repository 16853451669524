import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers'
import address from '../abis/contractAddress.json'
import abi from '../abis/src/contracts/Auction.sol/Auction.json'
import Identicon from 'react-identicons';
import Countdown from '../components/Countdown';
import { useGlobalState, setGlobalState, truncate } from '../store';
import { claimPrize, loadAuction, loadBidders, purchaseNFT } from '../services/blockchain';
import { toast } from 'react-toastify';
import axios from 'axios';

const NFT = () => {
  const [auction] = useGlobalState('auction');
  const [bidders] = useGlobalState('bidders');
  const [connectedAccount] = useGlobalState('connectedAccount');
  const { id } = useParams();


  useEffect(() => {
    const fetchData = async () => {
      await loadAuction(id);
      await loadBidders(id);
    };

    fetchData();
  }, [id]);

  return (
    <div
      className="grid lg:grid-cols-2 gap-2
        md:gap-1 lg:gap-3 py-2.5 text-white font-sans capitalize
        w-4/5 mx-auto justify-center items-center mt-11"
    >
      <div className="h-[370px] bg-gradient-to-br from-black to-purple-900 bg-opacity-40 rounded-md shadow-xl
  shadow-black md:w-3/5 md:items-center lg:w-[300px] md:mt-0 ">
        <img src={auction?.image} alt={auction?.name} className="object-contain w-full h-64 mt-10 " />
      </div>

      <div>
        <Details auction={auction} account={connectedAccount} />

        {bidders.length > 0 ? <Bidders auction={auction} bidders={bidders} /> : null}

        <CountdownNPrice auction={auction} />

        {auction?.live && Date.now() < auction?.duration ? (
          <ActionButton auction={auction} account={connectedAccount} />
        ) : null}
      </div>
    </div>
  );
};

const Details = ({ auction, account }) => {
  const [traits, setTraits] = useState([]);
  const ContractAddress = address.address
  const ContractAbi = abi.abi

  const getEthereumContract = async () => {
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    const contract = new ethers.Contract(ContractAddress, ContractAbi, signer)
    return contract
  }

  useEffect(() => {
    const getTraitData = async () => {
      try {
        // Replace getEthereumContract with the appropriate function that returns the Ethereum contract instance
        const contract = await getEthereumContract();
        const rawUri = await contract.tokenURI(auction.tokenId);
        const cleanUri = rawUri.replace('ipfs://', 'https://ipfs.io/ipfs/');
        const metadata = await axios.get(cleanUri);
        const attributes = metadata.data.attributes;
        const traitData = attributes.map(attribute => attribute.value);
        setTraits(traitData);
      } catch (error) {
        console.error('Error fetching trait data:', error);
        setTraits([]);
      }
    };

    getTraitData();
  }, [auction]);

  return (
    <div className="py-2">
      <h1 className="font-bold font-Recoleta text-[24px] mb-1  ">{auction?.name}</h1>
      <p className="font-semibold text-sm">
        <span className="text-black font-Recoleta">
          {auction?.owner === account ? 'You' : auction?.owner ? truncate(auction?.owner, 4, 4, 11) : null}
        </span>
      </p>
      <p className="text-sm py-2 mb-4 mt-4 font-Recoleta font-medium">{auction?.description}</p>

      <span className="font-bold font-Recoleta mr-2  ">Traits:</span>

      <div className="flex flex-wrap py-2">
        
        {traits.map((trait, index) => (
          <span
            key={index}
            className="bg-[#B549F5] rounded-[15px] w-[130px] text-center mb-4 mr-4 py-3"
          >
            {trait}
          </span>
        ))}
      </div>
    </div>
  );
};

const Bidders = ({ bidders, auction }) => {
  const handlePrizeClaim = async (bidderId) => {
    await toast.promise(
      new Promise(async (resolve, reject) => {
        await claimPrize(auction.tokenId, bidderId)
          .then(() => resolve())
          .catch((error) => reject(error));
      }),
      {
        pending: 'Transferring NFT...',
        success: 'Transfer completed...',
        error: 'An error occurred',
      }
    );
  };

  const [connectedAccount] = useGlobalState('connectedAccount');
  return (          
    <div className="flex flex-col">
      <span className="font-bold text-[17px] font-Recoleta">Top Bidders</span>
      <div className="h-[calc(100vh_-_40.5rem)] overflow-y-auto">
        {bidders.map((bid, i) => (
          <div key={i} className="flex justify-between items-center">
            <div className="flex justify-start items-center my-1 space-x-1">
              <Identicon
                className="h-5 w-5 object-contain bg-gray-800 rounded-full"
                size={18}
                string={bid.bidder}
              />
              <span className="font-medium teext-sm mr-3">
                {connectedAccount === bid.bidder ? '@You' : truncate(bid.bidder, 4, 4, 11)}
              </span>
              <span className="text-green-400 font-medium text-sm">{bid.price} PS</span>
            </div>

            {bid.bidder === auction?.winner && connectedAccount === bid.bidder && Date.now() > auction.duration ? (
              <button
              className="bg-blue-600 hover:bg-blue-800 text-white py-2 px-4 rounded-lg text-sm cursor-pointer font-medium font-Recoleta shadow-md transition duration-200 ease-in-out"
              onClick={() => handlePrizeClaim(bid.bidderId)}
            >
              Claim NFT
            </button>
            
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

const CountdownNPrice = ({ auction }) => {
  return (
    <div className="flex justify-between items-center py-5">
      <div>
        <span className="font-bold text-[17px] font-Recoleta">Current Price</span>
        <p className="text-sm font-Recoleta">{auction?.price}</p>
      </div>

      <div className="lowercase">
        <span className="font-bold">
          {auction?.duration > Date.now() ? <Countdown timestamp={auction?.duration} /> : '00:00:00'}
        </span>
      </div>
    </div>
  );
};

const ActionButton = ({ auction, account }) => {
  const onPlaceBid = () => {
    setGlobalState('auction', auction);
    setGlobalState('bidModal', 'scale-100');
  };

  const handleNftPurchase = async () => {
    await toast.promise(
      new Promise(async (resolve, reject) => {
        await purchaseNFT(auction)
          .then(() => resolve())
          .catch((error) => reject(error));
      }),
      {
        pending: 'Transferring NFT...',
        success: 'Transfer completed...',
        error: 'An error occurred',
      }
    );
  };
  const [connectedAccount] = useGlobalState('connectedAccount');
  return (
    <div className="flex justify-start items-center space-x-2 mt-2">
     {auction?.biddable ? (
  connectedAccount === auction.seller ? (
    <button className="bg-transparent w-full h-[40px] p-2 text-center font-bold rounded-[8px] font-Recoleta border-white border-2 mb-2">
      Auction Live
    </button>
  ) : (
    <button
      className="text-white bg-transparent border-white border-2 px-6 py-1 rounded-[12px] md:text-[20px] rounded-sm cursor-pointer font-Recoleta"
      onClick={onPlaceBid}
    >
      Place a Bid
    </button>
  )
) : (
  <button
    className="text-white bg-transparent font-Recoleta border-white md:text-xs p-2.5 rounded-sm cursor-pointer font-light"
    onClick={handleNftPurchase}
  >
    Buy NFT
  </button>
)}
    </div>
  );
};

export default NFT;