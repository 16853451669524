import { Link } from 'react-router-dom'
import { connectWallet } from '../services/blockchain'
import { truncate, useGlobalState } from '../store'
import logo from '../assets/images/logoo.png';

const Header = () => {
  const [connectedAccount] = useGlobalState('connectedAccount')

  return (
    <nav className="w-4/5 flex md:justify-center justify-between items-center py-4 mx-auto text-white">
      <div className="md:flex-[0.5] flex-initial justify-center items-center">
        <Link to="/">
        <div className="flex items-center ml-4 sm:ml-0">
  <img src={logo} alt="Logo" style={{ width: '70px' }} />
</div>
        </Link>
      </div>

      <ul
        className="md:flex-[0.5] hidden md:flex list-none justify-between
      item-center flex-initial"
      >
         <Link to="/" className="mx-4 font-Recoleta ">
          Home
        </Link>
        <Link to="/market" className="mx-4 font-Recoleta">
          Marketplace
        </Link>
        <Link to="/profile" className="mx-4 font-Recoleta">
          Profile
        </Link>
        <Link to="/activity" className="mx-4 font-Recoleta">
          Activity
        </Link>
        <Link to="/royalties" className="mx-4 font-Recoleta">
          Royalties
        </Link>
       
      </ul>

      {!connectedAccount ? (
        <button
          className="bg-white rounded-[20px] text-[#82A5FF] ml-[90px]
          hover:bg-gray md:text-sm sm:text-base p-2
          rounded-full font-Recoleta"
          onClick={connectWallet}
        >
          Connect Wallet
        </button>
      ) : (
        <button
          className="bg-white rounded-[20px] text-[#82A5FF] ml-[90px]
          hover:bg-gray md:text-sm sm:text-base p-2
          rounded-full font-Recoleta"
        >
          {truncate(connectedAccount, 4, 4, 11)}
        </button>
      )}
    </nav>
  )
}

export default Header
