import React, { useState, useEffect } from 'react';
import { truncate, useGlobalState } from '../store'
import { ethers } from 'ethers'

import hero2 from '../assets/images/banner.png';
import community1 from '../assets/images/white-community.png';
import HONESTY from '../assets/images/white-flower.png';
import UTILITY1 from '../assets/images/white-utility.png';
import Richeez from '../assets/images/Richeez.mp4';
import gif from '../assets/images/animation.gif.mp4.gif';
import banner3 from '../assets/images/banner3.png';
import twitter from '../assets/images/twitter.svg';
import telegram from '../assets/images/telegram.png';
import { toast } from 'react-toastify';
import { checkIfWhitelisted } from '../services/blockchain';
import { createNFTItem } from '../services/blockchain';
import { createNFTItemFree } from '../services/blockchain';
import { FaTimes } from 'react-icons/fa'
import picture6 from '../assets/images/picture6.png'
import NFT from '../assets/images/card1.png';
import { connectWallet } from '../services/blockchain';
import { getEthereumContract } from '../services/blockchain';

const LandingPage = () => {
  const [boxModal, setBoxModal] = useState('scale-0');
  const [count, setCount] = useState(1);
  const [totalSupply, setTotalSupply] = useState("?");

  const [imgBase64] = useState(picture6) // Default image URL
  const [connectedAccount] = useGlobalState('connectedAccount')
  const addressL = "0x02c097482c58fb98a798b4e61f2160efefef8b94";



  const checkIfWhitelisted = async (address) => {
    if (!ethereum) throw new Error('Please install Metamask');
    const contract = await getEthereumContract();
  
    const isWhitelisted = await contract.isFreeMinter(connectedAccount);
    console.log(`Is ${connectedAccount} isFreeMinter? ${isWhitelisted}`);

    return isWhitelisted;
  }

  const loadTotalSupply = async (tokenId) => {
    try {
      // if (!ethereum) return alert('Please install Metamask')
      const contract = await getEthereumContract()
      const supply = await contract.totalSupply()
      setTotalSupply(supply.toString());
    } catch (error) {
      console.log("error"+error)
    }
  }

  const loadLiveAuctions = async () => {
    try {
      const contract = await getEthereumContract()
      let auctions = await contract.getLiveAuctions()  
      // Calculate floor price from live auctions
      if (auctions.length > 0) {
        let floorPrice = ethers.BigNumber.from(auctions[0].price);
        for (const auction of auctions) {
          if (ethers.BigNumber.from(auction.price).lt(floorPrice)) {
            floorPrice = ethers.BigNumber.from(auction.price);
          }
        }
        // Convert floor price from Wei to Ether
        floorPrice = ethers.utils.formatEther(floorPrice);
        console.log(`Floor price: ${floorPrice}`);
      }
    } catch (error) {
      reportError(error)
    }
  }
  
  

  const isConnect = async () => {
    if (connectedAccount){
      console.log("address: "+connectedAccount);
      console.log(`Type of connectedAccount: ${typeof connectedAccount}`);

    }
    else 
    console.log("asba")
  }
  
  useEffect(() => {
    // This effect runs whenever connectedAccount changes
    if (connectedAccount) {
      console.log(`Connected account is now: ${connectedAccount}`);
      // Perform other actions depending on connectedAccount
    } else {
      console.log('No account is connected');
    }
  }, [connectedAccount]);


  useEffect(() => {
    loadTotalSupply();
    loadLiveAuctions();
 }, []);

 
  const handleMint = async (number, connectedAccount,) => {
    try {
      // const isWhitelisted = await checkIfWhitelisted(connectedAccount);
  
      await toast.promise(
        createNFTItem(count),
        {
          pending: 'Minting in progress...',
          success: 'Minting completed!',
          error: 'Encoutered an error',

        }
      );
    } catch (error) {
      console.log("An error occurred during the minting process: ", error);
    } finally {
      onClose();
    }
  };

  const handleMintFree = async (connectedAccount,) => {
    try {
      const isWhitelisted = await checkIfWhitelisted(connectedAccount);
  
      await toast.promise(
        createNFTItemFree(isWhitelisted),
        {
          pending: 'Minting in progress...',
          success: 'Minting completed!',
          error: 'Encoutered an error',

        }
      );
    } catch (error) {
      console.log("An error occurred during the minting process: ", error);
    } finally {
      onClose();
    }
  };
  
  
  
  const onClose = () => {
    setBoxModal('scale-0');
  }

  const handleModal = () => {
    setBoxModal(boxModal === 'scale-0' ? 'scale-100' : 'scale-0');
  }

  const handleIncrement = () => {
    if (count < 10 ) {

    setCount(prevCount => prevCount + 1);}
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(prevCount => prevCount - 1);
    }
  };

  // Rest of your component...

  return (
    <div className="bg-[#B549F5]">
        {/* <button
          className="bg-white rounded-[20px] text-[#82A5FF] ml-[90px]
          hover:bg-gray md:text-sm sm:text-base p-2
          rounded-full font-Recoleta"
          onClick={isConnect}
        >
          Connect Wallet
        </button>

        <button
          className="bg-white rounded-[20px] text-[#82A5FF] ml-[90px]
          hover:bg-gray md:text-sm sm:text-base p-2
          rounded-full font-Recoleta"
          onClick={checkIfWhitelisted}
        >
          checkIfWhitelisted 
        </button> */}

      {/* ... Rest of your JSX ... */}

      <div className="relative w-full">
  <img src={hero2} alt="hero" className="w-full" />
  <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center mb-1">
  <div className="text-white font-Recoleta text-2xl sm:text-5xl lg:text-6xl mx-auto flex flex-col items-center">
  <div>
    <h1>
      {totalSupply} Richeez Minted / 5555
    </h1>
  </div>
  <button
    type="button"
    onClick={handleMintFree} 
    className="text-white text-[24px] bg-transparent border border-white border-2 mt-5 mb-4 py-2 px-10 rounded-[12px] font-Recoleta drop-shadow-xl hover:bg-transparent hover:text-white focus:ring-0 hover:bg-[#82A5FF] hover:border hover:border-[#82A5FF] focus:outline-none"
  >
    FreeMint
  </button>

  <button
    type="button"
    onClick={handleModal} 
    className="text-white text-[24px] bg-transparent border border-white border-2 mt-5 mb-4 py-2 px-10 rounded-[12px] font-Recoleta drop-shadow-xl hover:bg-transparent hover:text-white focus:ring-0 hover:bg-[#82A5FF] hover:border hover:border-[#82A5FF] focus:outline-none"
  >
    Public Mint
  </button>
</div>

   
  </div>
      
</div>


<div className="flex flex-col sm:flex-row justify-between center-items ml-4 sm:ml-8 md:ml-16 lg:ml-20">
  <div className="ml-4 sm:ml-8 md:ml-12 lg:ml-16 mt-4 sm:mt-[150px]">
    <img src={gif} className="h-auto sm:h-[300px] md:h-[400px] lg:h-[500px] " />
  </div>
  <div className="font-Recoleta text-black mt-8 sm:mt-0 sm:mr-12 md:mr-16 lg:mr-20 max-w-full sm:max-w-[40%] sm:mt-[274px]">
    <div className="mb-4 sm:mb-6 md:mb-9 text-lg sm:text-xl md:text-2xl lg:text-3xl text-white">
      INTRODUCTION
    </div>

    <div className="mb-3 sm:mb-4 md:mb-5 text-black ">
      Richeez World has a quirky crew of 5555 unique, brightly coloured NFTs living in a universe called Pulsedoodlia, inspired by our King, the Big Cheez himself, Richard Heart. The artwork shows his prized possessions as well as the kooky traits that made him famous. Our Profile Picture (PFP) NFTs are made to emulate the attitude and flare of Richard Heart.
    </div>
    <div className="mb-5 text-black">
      Own a one-of-a-kind citizen of Richeez World and let your imagination explore this brand-new landscape.
    </div>
    <button
        type="button"
        onClick={handleModal} 
        className="text-white text-[24px] bg-transparent border border-white border-2 mt-5 mb-4 py-2 px-10 rounded-[12px] font-Recoleta drop-shadow-xl  hover:bg-transparent hover:text-white focus:ring-0  hover:bg-[#82A5FF] hover:border hover:border-[#82A5FF] focus:outline-none"
      >
        Mint Now
      </button>
  </div>
</div>
<video
  src={Richeez}
  autoPlay
  loop
  muted
  playsInline
  className="w-screen  mt-[160px]"
></video>

<div className="flex justify-center mt-10">
  <div className="border border-[2px] rounded-md border-gray-300 p-6 max-w-[600px]">
    <h3 className="text-white text-3xl sm:text-4xl font-Recoleta mt-8 sm:mt-10">CORE VALUES</h3>
    <div className="text-back font-Recoleta mt-4 sm:mt-6 space-y-2">
      <div className="flex items-start">
        <p className="bullet-point">&#8226;</p>
        <p className="ml-2">To create a passive income opportunity for our Richeez World citizens.</p>
      </div>
      <div className="flex items-start">
        <p className="bullet-point">&#8226;</p>
        <p className="ml-2">Build a community where NFT lovers can come together and get their own piece of passive income.</p>
      </div>
      <div className="flex items-start">
        <p className="bullet-point">&#8226;</p>
        <p className="ml-2">Driven by our own unique community.</p>
      </div>
      <div className="flex items-start">
        <p className="bullet-point">&#8226;</p>
        <p className="ml-2">Honesty and integrity.</p>
      </div>
      <div className="flex items-start">
        <p className="bullet-point">&#8226;</p>
        <p className="ml-2">Treat the community as our own.</p>
      </div>
      <div className="flex items-start">
        <p className="bullet-point">&#8226;</p>
        <p className="ml-2">Building relationships.</p>
      </div>
      <div className="flex items-start">
        <p className="bullet-point">&#8226;</p>
        <p className="ml-2">Inspire the belief that Richeez World has the power to ignite your wildest imagination!</p>
      </div>
    </div>
  </div>
</div>

<div className="flex flex-wrap justify-center mt-10">
  <div className="mt-10 mx-2 sm:mx-4 md:mx-8 lg:mx-10"> 
    <div className="border border-[2px] rounded-md border-gray-300 p-4 max-w-[500px] h-full">
      <div className="flex justify-center">
        <img src={community1} alt="Image" className="w-[130px] sm:w-[150px] md:w-[180px] lg:w-[200px] mt-10" />
      </div>
      <div className="mx-5">
        <h3 className="text-white text-2xl sm:text-3xl font-Recoleta mt-8 sm:mt-10">COMMUNITY</h3>
        <ul className="text-back font-Recoleta mt-4 sm:mt-6 list-disc ml-6">
          <li>
            As promised and set out in our core values, we strive to be fully community driven. By Pulsicans for Pulsicans!
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div className="mt-10 mx-2 sm:mx-4 md:mx-8 lg:mx-10"> 
    <div className="border border-[2px] rounded-md border-gray-300 p-4 max-w-[500px] h-full">
      <div className="flex justify-center">
        <img src={HONESTY} alt="Image" className="h-[130px] sm:h-[150px] md:h-[180px] lg:h-[200px] mt-10" />
      </div>
      <div className="mx-4">
        <h3 className="text-white text-2xl sm:text-3xl font-Recoleta mt-8 sm:mt-10">HONESTY</h3>
        <ul className="text-black font-Recoleta mt-4 sm:mt-6 list-disc ml-6">
          <li>
            No promises, no roadmap. Just passive income and fantastic art for our community.
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div className="mt-10 mx-2 sm:mx-4 md:mx-8 lg:mx-10"> 
    <div className="border border-[2px] rounded-md border-gray-300 p-4 max-w-[500px] h-full">
      <div className="flex justify-center">
        <img src={UTILITY1} alt="Image" className="h-[130px] sm:h-[150px] md:h-[180px] lg:h-[200px] mt-10" />
      </div>
      <div className="mx-4">
        <h3 className="text-white text-2xl sm:text-3xl font-Recoleta mt-8 sm:mt-10">UTILITY</h3>
        <ul className="text-black mt-4 sm:mt-6 font-Recoleta mb-5 list-disc ml-6">
          <li>
            All royalty fees on every secondary sale will equally and fairly be distributed by the royalty pool to all holders of Richeez World. Creating a passive income in the native $PLS coin.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>




<div className="flex justify-center items-center h-screen  mt-12">
  <div className="relative bg-[#B549F5] ">
    <img src={banner3} alt="Image" />
    <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-14">
      <h2 className="font-Recoleta  text-[#80B1FF] text-6xl mt-10 sm:mt-16 md:mt-20 lg:mt-24 text-center mb-12">
        WHO WE ARE 
      </h2>

      <div className="">
  <div className="flex flex-col sm:flex-row items-start space-x-2 sm:space-x-4 mt-12">
    <span className="text-white font-Recoleta">&#8226;</span>
    <p className="text-[18px] text-white font-Recoleta">
      Welcome to Richeez World. As a salute to Richard Heart, we have created 5555 unique little Richeez living on Pulsechain. The first Doodles NFT collection on Pulsechain.
    </p>
  </div>
  <div className="flex flex-col sm:flex-row items-start space-x-2 sm:space-x-4 mt-12">
    <span className="text-white font-Recoleta">&#8226;</span>
    <p className="text-[18px] text-white font-Recoleta">
      We are ordinary Pulsicans with an enthusiasm for incredible art.
    </p>
  </div>
  <div className="flex flex-col sm:flex-row items-start space-x-2 sm:space-x-4 mt-12">
    <span className="text-white font-Recoleta">&#8226;</span>
    <p className="text-[18px] text-white font-Recoleta">
      Purchase your own Richeez today and become part of the Pulsedoodlia world!
    </p>
  </div>
</div>


    </div>
  </div>
</div>    

<div className="mt-20 mx-4 sm:mx-8 md:mx-12 lg:mx-16">
  <h2 className="font-Recoleta text-white text-3xl mb-8">
    Frequently Asked Questions
  </h2> 
  <div className="space-y-8">
    <div>
      <h3 className="font-Recoleta text-[#80B1FF] text-2xl mb-4">
        What is Richeez World?
      </h3>
      <p className="text-white text-lg font-Recoleta">
        Welcome to Richeez World. As a salute to Richard Heart, we have created 5555 unique little Richeez living on Pulsechain. The first Doodles NFT collection on Pulsechain.
      </p>
    </div>

    <div>
      <h3 className="font-Recoleta text-[#80B1FF] text-2xl mb-4">
        How do I buy Richeez World?
      </h3>
      <p className="text-white text-lg font-Recoleta">
        As long as the minting phase lasts, you can mint yourself a Richeez World NFT and start benefiting from the Royalties from our marketplace. We will have our own marketplace to trade your favourite Richeez World NFTs. However, Pulsechain Marketplaces like Mintra, Hex Toys and BeatBox will also offer the possibility to buy and sell Richeez World NFTs on the secondary market.
      </p>
    </div>

    <div>
      <h3 className="font-Recoleta text-[#80B1FF] text-2xl mb-4">
        What is the supply?
      </h3>
      <p className="text-white text-lg font-Recoleta">
        Richeez World will have total supply of 5555 broken down in the following categories:
        Shark costume (0.14%)
        Shrimp costume (0.14%)
        Skeleton (2%)
        Alien (1%)
        Zombie (1%)
        Human (95.72%)
      </p>
    </div>

    <div>
      <h3 className="font-Recoleta text-[#80B1FF] text-2xl mb-4">
        What is the pricing and how many can I mint?
      </h3>
      <p className="text-white text-lg font-Recoleta">
        Whitelisted wallets will be able to mint one Richeez World NFT for 300 000 PLS.
        Public mint will be 400 000 PLS.
        This will be an open mint without any limits. The max mint per transaction will be 10 Richeez World NFTs.
      </p>
    </div>

    <div>
      <h3 className="font-Recoleta text-[#80B1FF] text-2xl mb-4">
        What is the utility?
      </h3>
      <p className="text-white text-lg font-Recoleta">
        Every secondary sale of Richeez World NFTs sends 6% to the royalty pool which will be fairly and proportionally to your holdings be sent to your wallet every 3 months, to encourage holding and earning a passive income in Pulsechain native tokens.
        With each rewards period, an event will be held with the community.
      </p>
    </div>

    <div>
      <h3 className="font-Recoleta text-[#80B1FF] text-2xl mb-4">
        Did someone say bounties?
      </h3>
      <p className="text-white text-lg font-Recoleta">
        Yes, for rarities numbers #1, #9, #55, #555 and #5555 there will be a 5 000 000 PLS bounty. These bounties will be sent out to the respective minters once all NFTs have been minted out.
      </p>
    </div>

    <div>
      <h3 className="font-Recoleta text-[#80B1FF] text-2xl mb-4">
        Future roadmap?
      </h3>
      <p className="text-white text-lg font-Recoleta">
        We are working closely with our founding brand, JayGLabs. There will be future projects not too far away and a native token with staking coming soon. The token will be called Gee token. And even a potential airdrop to holders. More info on this soon.
      </p>
    </div>
  </div>
</div>




<div className="flex justify-center items-center mb-[300px] mt-[100px]">
  <div className="text-center">
    <h2 className="font-Recoleta  text-white text-4xl mb-12">
      JOIN THE COMMUNITY
    </h2> 
    <div className="flex justify-center space-x-4 mb-20">
    <a href="https://t.me/RicheezWorld" target="_blank" rel="noopener noreferrer">
    <button className="rounded-full bg-[#80B1FF] text-white py-3 px-6 font-medium text-lg shadow-md mr-4 font-Recoleta text-[16px]">
    <img src={telegram} alt="Telegram Icon" className="w-6 h-6 inline-block mr-2 " />
          TELEGRAM
        </button>
        </a>
        <a href="https://twitter.com/RicheezWorld" target="_blank" rel="noopener noreferrer">
        <button className="rounded-full bg-[#FC7CC5] text-white py-3 px-6 font-medium text-lg shadow-md font-Recoleta text-[16px]">
        <img src={twitter} alt="Twitter Icon" className="w-6 h-6 inline-block mr-2" />
          TWITTER
        </button>

        </a>
    </div>
  </div>
</div>

      
   
      
      {/* ... Rest of your JSX ... */}
      
     
      
      {/* ... Rest of your JSX ... */}
      
      {/* The CreateNFT modal code goes here... */}

      <div
      className={`fixed top-0 left-0 w-screen h-screen flex items-center
      justify-center bg-black bg-opacity-50 transform
      transition-transform duration-300 ${boxModal}`}
    >
        <div
        className="bg-[#B549F5]  rounded-xl
        w-11/12 sm:w-2/5 h-7/12 p-6"
      >
        <div className="flex justify-between items-center text-white">
          <p className="font-semibold font-Recoleta">Mint NFT</p>
          
          <button
            type="button"
            className="border-0 bg-transparent focus:outline-none"
            onClick={onClose}
          >
            <FaTimes />
          </button>
        </div>

        <div className="flex justify-center items-center mt-5">
          <div className="shrink-0 rounded-xl overflow-hidden h-40 w-40">
            <img
              src={NFT}
              alt="Artwork"
              className="h-full w-full object-cover cursor-pointer"
            />
          </div>
        </div>

        <div className="flex items-center justify-center mt-5">
          <button
            type="button"
            
            onClick={handleDecrement}
            className="text-2xl text-black focus:outline-none"
          >
            -
          </button>
          <span className="text-[30px] mx-3 font-Recoleta">{count}</span>
          <button
            type="button"
            onClick={handleIncrement}
            className="text-2xl text-black focus:outline-none"
          >
            +
          </button>
        </div>

        <button
          type="button"
          onClick={handleMint}
          className="flex justify-center items-center
          w-full text-black text-md bg-white mt-5
          py-2 px-5 rounded-full  border border-transparent hover:text-[#82A5FF] focus:ring-0
          focus:outline-none font-Recoleta"
        >
          Mint Now
        </button>
      </div>

    </div>

    </div>
  );
};

export default LandingPage;