const Empty = () => {
  return (
    <div className="w-4/5 h-48 py-10 justify-center">
      <h4 className="text-xl capitalize text-white mb-4">
      Absent Illustrations, Import Some Masterpieces.
      </h4>
    </div>
  )
}

export default Empty
