import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div
      className="w-4/5 flex flex-col sm:flex-row justify-between
    items-center my-4 mx-auto py-5 text-white"
    >
           <div className="hidden sm:flex  justify-start items-center text-base space-x-12">
      <Link to="/" className=" cursor-pointer mx-4 font-Recoleta ">
          Home
        </Link>
        <Link to="/Market" className=" cursor-pointer mx-4 font-Recoleta ">
        Market
        </Link>
        <Link to="/collections" className="cursor-pointer mx-4 font-Recoleta ">
        Collections
        </Link>
      </div>

      <p className="text-right text-xs">&copy;2023 All rights reserved.</p>
    </div>
  )
}

export default Footer
