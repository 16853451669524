import { useEffect, useState } from 'react'
import Artworks from '../components/Artworks'
import CreateNFT from '../components/CreateNFT'
import Empty from '../components/Empty'
import Hero from '../components/Hero'
import { loadLiveLiveAuctions } from '../services/blockchain'
import { useGlobalState } from '../store'
import SmoothScrollbarWrapper from '../components/SmothScrollbarWrapper';
import Reset from '../assets/images/reset.svg';
import searchIcon from '../assets/images/search.svg';
import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Scrollbar } from 'smooth-scrollbar-react';
import RankIcon from '../assets/images/rank.svg';
import StatusIcon from '../assets/images/status.svg';
import PriceIcon from '../assets/images/price.svg';
import TypeIcon from '../assets/images/type.svg';
import backgroundIcon from '../assets/images/background.png';
import CostumeIcon from '../assets/images/custume.png';
import EyesIcon from '../assets/images/eyes.png';
import FaceIcon from '../assets/images/face.png';
import HandIcon from '../assets/images/hand.png';
import HeadIcon from '../assets/images/head.png';
import MouthIcon from '../assets/images/mouth.png';


const FilterSection = ({ traitType, values, handleCheckboxChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };


  const getTraitIcon = (traitType) => {
    // Define the icons for each trait type
    const traitIcons = {
      Background: <img src={backgroundIcon} alt="Background Icon" />,
      Costume: <img src={CostumeIcon} alt="Costume Icon" />,
      Face: <img src={FaceIcon} alt="Face Icon" />,
      Mouth: <img src={MouthIcon} alt="Mouth Icon" />,
      Eyes: <img src={EyesIcon} alt="Eyes Icon" />,
      Head: <img src={HeadIcon} alt="Head Icon" />,
      Hand: <img src={HandIcon} alt="Hand Icon" />,
    };

    return traitIcons[traitType] || null;
  };

  return (
    <div className=" p-2  mb-5 ">
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleOpen}>
      <div className="flex items-center">
          <div className='h-5 w-5 mr-2'>{getTraitIcon(traitType)}</div>
          <h2 className="text-white ml-2 font-Recoleta">{traitType}</h2>
        </div>
                <ChevronDownIcon className={`w-4 h-4 mr-7 transition-transform  mt-2  text-white  ${isOpen ? 'transform rotate-180' : ''}`} />
        
      </div>
      {isOpen && (
        <div className="mt-2">
          {values.map((value, index) => (
            <div key={index} className="flex items-center">
              <label className="flex items-center mt-1">
              <input
                type="checkbox"
                className="form-checkbox rounded ml-2 "
                value={value}
                onChange={(e) => handleCheckboxChange(traitType, e.target.value, e.target.checked)}
                
              />
              <label className="font-Recoleta ml-2 text-white text-[12px]">{value}</label>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


const Home = () => {
  const [auctions] = useGlobalState('auctions')
  const [filters, setFilters] = useState({
    Background: [],
    Costume: [],
    Face: [],
    Mouth: [],
    Eyes: [],
    Head: [],
    Hand: []
  });

  // Hardcoded example trait values, you would probably want to dynamically generate these in your actual code
  const traitValues = {
    Background: ['pulsecolor1', 'pulsecolor2', 'pulsecolor3', 'pulsecolor4', 'pulsecolor5', 'pulsecolor6', 'pulsecolor7'],
    Costume: ['black_signstshirt', 'branded_jacket', 'dollars_hoodie', 'gucci_jacket', 'hex_jacket', 'hex_tshirt', 'hoodie', 'pink_luxury'],
    Face: ['Face', 'alien', 'zombie'],
    Mouth: ['cigar', 'grass', 'greedy', 'happy_mouth', 'rainbow_mouth', 'teeth', 'flat_mouth'],
    Eyes: ['angry_eyes', 'bored_eyes', 'dead_eyes', 'default_eyes', 'sad_eyes', 'weired_eyes', 'alien_eyes'],
    Head: ['black_tophat', 'cowboy', 'flyies', 'green_cap', 'hex_crown', 'shrimp', 'whale', 'wizzard', 'yellow_hair'],
    Hand: ['black_diamond', 'hex_coin', 'money', 'nohands', 'thumbsup', 'waving'],
  };
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(async () => {
    await loadLiveLiveAuctions()
  }, [])

  useEffect(async () => {

    await loadLiveLiveAuctions(filters);
  }, [filters]);  


  

  const handleCheckboxChange = (traitType, traitValue, checked) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      if (checked) {
        newFilters[traitType].push(traitValue);
      } else {
        newFilters[traitType] = newFilters[traitType].filter(value => value !== traitValue);
      }
      return newFilters;
    });
  };

  const handleResetFilters = () => {
    // Clear filters state
    setFilters({
      Background: [],
      Costume: [],
      Face: [],
      Mouth: [],
      Eyes: [],
      Head: [],
      Hand: []
    });
    

    // Clear checked checkboxes
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAuctions = auctions.filter((auction) => {
    // Filter by auction name
    if (searchTerm) {
      return auction.name.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return true;
  });

  useEffect(() => {
    // ...
  }, [filters]);
  return (
    <div className="w-4/5 mx-auto mt-11">
  
      <div className="flex flex-wrap">
  
        <div className="bg-[#B549F5] w-full sm:w-72 max-h-[calc(100vh-10px)] p-4 overflow-none rounded-[11px] mt-4 sm:mt-0 mr-4">
          <div className="flex justify-between items-center mb-5 mt-3">
            <h2 className="text-white text-[16px] font-Recoleta font-bold ml-2">FILTERS</h2>
            <div className="flex items-center space-x-2">
              <button
                className="text-[#ECECEC] text-[9px] font-Recoleta font-medium"
                onClick={handleResetFilters}
              >
                Reset Filters
              </button>
              <img src={Reset} alt="Reset Icon" className="w-6 h-6" />
            </div>
          </div>
          <Scrollbar
            damping={0.1}
            thumbMinSize={20}
            renderByPixels={true}
            alwaysShowTracks={false}
            continuousScrolling={true}
            wheelEventTarget={null}
            plugins={{}}
            onScroll={() => {}}
          >
            <div className="bg-[#B549F5] w-full sm:w-[250px] max-h-[calc(100vh-224px)] rounded-[11px] overflow-hidden">
              <div className="relative">
                <hr className="border-t border-white" />
                <div className="flex items-center mb-3 mt-4">
                  <img src={searchIcon} alt="Icon" className="w-4 h-4 mr-2 ml-2" />
                  <input
                    type="text"
                    placeholder="Filter by Token ID"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="outline-none bg-transparent border-none placeholder-[#EBEBEB] text-[14px] py-2 focus:ring-0 focus:border-gray-300 text-white font-Recoleta"
                  />
                </div>
                <hr className="border-gray-300 my-3 mr-[30px] " />
              </div>
  
              <div>
                {Object.entries(traitValues).map(([traitType, values], index) => (
                  <React.Fragment key={traitType}>
                    <FilterSection
                      traitType={traitType}
                      values={values}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    {index !== Object.entries(traitValues).length - 1 && (
                      <hr className="border-gray-300 my-2 mr-[30px]  " />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Scrollbar>
        </div>
  
        <div className="w-full sm:flex-1 ">
          {filteredAuctions.length > 0 ? <Artworks auctions={filteredAuctions} /> : <Empty />}
          <CreateNFT />
        </div>
      </div>
  
    </div>
  );
  
}

export default Home